@charset 'UTF-8';
@import "/Users/nagayamegumi/Sites/fytte/src/scss/utility/_media-queries.scss";
@import "/Users/nagayamegumi/Sites/fytte/src/scss/utility/_mixin.scss";


body {
  background: #fff;
}

#wrap {
  @include PC {
    padding-top: 33px;
  }
}

.top-slider {
  max-width: 968px;
  margin: 0 auto 38px;
  overflow: inherit;
  position: relative;
  .js-image {
    @include aspect-ratio(710, 457);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  }
  .swiper-wrapper {
    .swiper-slide {
      img {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      }
    }
  }
  .swiper-button-disabled {
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
  }
  .swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 46px;
  }
  .top-button-prev, .top-button-next {
    width: 62px; height: 62px;
    margin-top: -64px;
    position: absolute;
    top: 50%;
    z-index: 199;
    cursor: pointer;
    @include add_prefix(transition, 0.3s ease all);
    &:after {
      display: none;
      content: "";
    }
    @include HOVER {
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .top-button-prev {
    background: url($img_url+'common/icon/icn_prev.svg') no-repeat center center;
    left: -130px;
    @include PC {
      @include max-screen(1220px) {
        left: auto;
        right: 92%;
      }
    }
  }
  .top-button-next {
    background: url($img_url+'common/icon/icn_next.svg') no-repeat center center;
    right: -130px;
    @include PC {
      @include max-screen(1220px) {
        right: auto;
        left: 92%;
      }
    }
  }
  @include SP {
    margin: 0 auto 9px;
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 0 3px;
    }
    .swiper-pagination {
      margin-top: 22px;
    }
    .top-button-prev, .top-button-next {
      width: 27px; height: 27px;
      margin-top: -38px;
    }
    .top-button-prev {
      background-size: 100% 100%;
      left: 10px;
    }
    .top-button-next {
      background-size: 100% 100%;
      right: 10px;
    }
  }
}

.sec01 {
  background: $pink01;
  padding: 76px 0 50px;
  .wcm02 {
    max-width: 1310px;
  }
  .cm-ttl01 {
    margin-bottom: 30px;
  }
  &-btn01 {
   text-align: center;
  }
  .cm-js-slider01{
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
    .mySwiper{
      padding-top: 50px;
    }
  }
  &-btn02 {
    margin-top: 29px;
    text-align: center;
  }
  .img-bnr-wrap{
    max-width: 640px;
    margin: 33px auto 0;
    position: relative;
    .img-bnr{
      overflow: hidden;
      .swiper-slide{
        padding: 0;
        a:hover{
          opacity: 1;
        }
      }
    }

    .swiper-pagination{
      margin-top: 15px;
      &-bullet{
        width: 13px;
        height: 13px;
      }
    }

    .bnr-slider-next
    ,.bnr-slider-prev
    {
      width: 25px;
      height: 25px;
      background-image: url($img_url+'common/icon/icn_next02.svg');
      background-repeat: no-repeat;
      background-size: 25px 25px;
      background-position: center;
      position: absolute;
      top: 44%;
      z-index: 10;
      border-radius: 50%;
      cursor: pointer;
    }
    .bnr-slider-prev{
      @include transform(translateY(-50%) rotate(-180deg));
      left: 15px;
    }
    .bnr-slider-next{
      @include transform(translateY(-50%));
      right: 15px;
    }
  }
  @include SP {
    padding: 40px 0 50px;
    .wcm02 {
      padding: 0;
    }
    .cm-ttl01 {
      margin-bottom: 25px;
    }
    &-btn01 {
     text-align: center;
    }
    .cm-js-slider01 .mySwiper{
      padding-top: 36px;
    }
    &-btn02 {
      margin-top: 13px;
      padding: 0 12%;
    }
    .img-bnr-wrap {
      margin-top: 30px;
      width: 100%;
      padding: 0 15px;

      .swiper-pagination{
        margin-top: 10px;
        &-bullet{
          width: 10px;
          height: 10px;
        }
      }

      .bnr-slider-next
      ,.bnr-slider-prev
      {
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        top: 40%;
      }
      .bnr-slider-prev{
        left: 22px;
      }
      .bnr-slider-next{
        right: 22px;
      }
    }
  }
}

.sec02 {
  background: $yellow01;
  padding: 70px 0 73px;
  .cm-ttl01 {
    margin-bottom: 48px;
  }
  .pickup-box {
    a {
      display: block;
      position: relative;
      .js-image {
        @include aspect-ratio(710, 457);
        @include PC {
          @include max-screen(960px) {
            @include aspect-ratio(710, 535);
          }
        }
      }
    }
    .block01-ttl01 {
      display: inline-block;
      vertical-align: middle;
      font-style: normal;
      @include ffRu;
      @include font(17,20,170,700);
      position: absolute;
      left: 41px; top: 32px;
      border: 2px solid #fff;
      color: #fff;
      padding: 10px 26px;
      z-index: 39;
      @include PC {
        @include max-screen(960px) {
          @include font(16,20);
          left: 25px; top: 25px;
          padding: 7px 15px;
        }
      }
    }
    .pickup-info {
      position: absolute;
      left: 0; right: 0; bottom: 0;
      -webkit-backdrop-filter: blur(33px);
      backdrop-filter: blur(33px);
      background-color: rgba(255, 255, 255, 0.5);
      padding: 18px 43px 23px;
      @include PC {
        @include max-screen(960px) {
          padding: 15px 30px 15px;
        }
      }
      @include IE {
        background-color: rgba(255, 255, 255, 0.85);
      }
    }
    .pickup-meta {
      display: flex;
      align-items: center;
      margin-bottom: 9px;
      .date {
        display: inline-block;
        vertical-align: top;
        @include ffRu;
        @include font(15,18,0,bold);
        margin: 5px 20px 0 0;
      }
      .cm-news-cats{
        margin-right: 20px;
      }
    }
    .pickup-ttl {
      @include font(20,30,0,bold);
      @include PC {
        @include max-screen(960px) {
          @include font(17,28);
        }
      }
    }
  }
  .block01-list {
    li {
      margin-bottom: 21px;
      @include PC {
        @include max-screen(960px) {
          margin-bottom: 15px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      a {
        background: #fff;
        text-align: center;
        position: relative;
        @include flexbox;
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        @include align-items(center);
        padding: 13px 23px 14px 28px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
        .txt {
          @include ffRu;
          @include font(18,22,150,bold);
          @include PC {
            @include max-screen(960px) {
              @include font(17,22);
            }
          }
        }
        .c-arr {
          width: 12px;
          margin-top: -4px;
          &:after {
            width: 7px;
          }
        }
        &.c-diet {
          .txt {
            color: #F56850;
          }
        }
        &.c-fitness {
          .txt {
            color: #006CA8;
          }
        }
        &.c-healthcare {
          .txt {
            color: #E5B506;
          }
        }
        &.c-beauty {
          .txt {
            color: #FBB5C1;
          }
        }
        &.c-lifestyle {
          .txt {
            color: #0A5127;
          }
        }
        @include HOVER {
          &:hover {
            .c-arr {
              @include transform(translateX(8px));
            }
          }
        }
      }
    }
  }
  .block01 {
    margin-bottom: 57px;
    @include PC {
      @include flexbox;
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      .pickup-box {
        width: calc(100% - 330px);
        @include max-screen(960px) {
          width: calc(100% - 290px);
        }
      }
      .block01-list {
        width: 290px;
        @include max-screen(960px) {
          width: 270px;
        }
      }
    }
  }
  .list-news {
    .item {
      &-thumb {
        width: 43.71%;
      }
      &-info {
        width: 56.29%;
        padding-left: 26px;
        @include PC {
          @include max-screen(1040px) {
            padding-left: 15px;
          }
        }
      }
      &-ttl {
        @include font(15,26,0,400);
        @include multiLineEllipsis(3);
        @include PC {
          @include max-screen(980px) {
            @include font(14,22);
          }
        }
      }
      &-meta {
        @include flexbox;
        @include flex-wrap(wrap);
        margin-bottom: 15px;
        width: 100%;
        @include PC {
          @include max-screen(980px) {
            margin-bottom: 8px;
          }
        }
        .date {
          @include ffRu;
          @include font(15,18,0,bold);
          margin: 5px 20px 0 0;
          @include PC {
            @include max-screen(1040px) {
              @include font(14,18);
              margin: 5px 10px 0 0;
            }
          }
        }
        .cm-news-cats {
          max-width: calc(100% - 100px);
          @include PC {
            @include max-screen(1040px) {
              span {
                min-width: 120px;
                width: 100%;
              }
            }
            @include max-screen(980px) {
              span {
                min-width: 90px;
              }
            }
          }
        }
      }
      a {
        @include flexbox;
        @include flex-wrap(wrap);
        position: relative;
        .js-image {
          @include aspect-ratio(210, 140);
        }
      }
    }
    @include PC {
      @include flexbox;
      @include flex-wrap(wrap);
      .item {
        width: 46.2%;
        margin: 0 7.6% 50px 0;
        @include PC {
          @include max-screen(980px) {
            width: 48%;
            margin: 0 4% 50px 0;
          }
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  .block02 {
    border-bottom: 1px solid $black;
    &-ttl01 {
      text-align: center;
      position: relative;
      margin-bottom: 45px;
      &:after {
        position: absolute; content: "";
        left: 0; right: 0; top: 50%;
        background: $black; height: 1px;
      }
      span {
        display: inline-block;
        background: $yellow01;
        position: relative;
        z-index: 25;
        @include ffBun;
        @include font(32,44,100,400);
        padding: 0 60px;
      }
    }
  }
  .sec02-btn01 {
    text-align: center;
    margin-top: 44px;
    .cm-btn02 {
      margin: 0 auto;
    }
  }
  @include SP {
    padding: 39px 0 44px;
    .cm-ttl01 {
      margin-bottom: 31px;
    }
    .pickup-box {
      margin-bottom: 15px;
      a {
        .js-image {
          @include aspect-ratio(345, 221);
        }
      }
      .block01-ttl01 {
        @include font(8,9);
        left: 20px;
        top: 15px;
        padding: 4px 12px;
      }
      .pickup-info {
        padding: 10px 15px 12px;
        position: relative;
        background: #fff;
      }
      .pickup-meta {
        margin-bottom: 1px;
        line-height: 1;
        .date {
          @include font(9,10);
          margin: 1px 12px 0 0;
        }
        .cm-news-cats{
          margin-right: 12px;
        }
      }
      .pickup-ttl {
        @include font(14,21);
      }
    }
    .block01-list {
      ul {
        @include flexbox;
        @include flex-wrap(wrap);
      }
      li {
        width: 48%;
        margin: 0 4% 13px 0;
        &:nth-child(2n) {
          margin-right: 0;
        }
        a {
          padding: 4px 12px;
          .txt {
            @include font(11,13);
            margin-right: 4px;
            text-align: left;
            white-space: nowrap;
          }
          .icon {
            margin-top: -2px;
            img {
              max-width: 23px;
              max-height: 21px;
            }
          }
          .c-arr {
            width: 8px;
            margin-top: -4px;
            &:after {
              width: 4px;
            }
          }
        }
      }
    }
    .block01 {
      margin-bottom: 30px;
    }
    .list-news {
      .item {
        margin-bottom: 23px;
        &:last-child {
          margin-bottom: 0;
        }
        &-thumb {
        }
        &-info {
          padding-left: 19px;
        }
        &-ttl {
          @include font(13,21);
        }

        &-meta {
          margin-bottom: 6px;
          .date {
            @include font(10,12);
            margin: 3px 13px 0 0;
          }
          .cm-news-cats {
            span {
              padding: 5px 2px;
              @include font(9,10);
              min-width: 85px;
            }
          }
          .cm-news-cats {
            max-width: calc(100% - 70px);
          }
        }
        a {
          .js-image {
            @include aspect-ratio(210, 140);
          }
        }
      }
    }
    .block02 {
      padding-bottom: 32px;
      &-ttl01 {
        margin-bottom: 20px;
        span {
          @include font(24,34);
          padding: 0 30px;
        }
      }
    }
    .sec02-btn01 {
      margin-top: 29px;
      padding: 0 15px;
    }
  }
}

.sec03 {
  position: relative;
  background: $green02;
  padding: 74px 0 0;
  .cm-ttl01 {
    margin-bottom: 43px;
  }
  .box-coming {
    position: absolute; content: "";
    left: 0; right: 0; bottom: 0;
    height: 160px;
    -webkit-backdrop-filter: blur(33px);
    backdrop-filter: blur(33px);
    background-color: rgba(255, 255, 255, 0.25);
    padding: 50px 20px 23px;
    text-align: center;
    @include IE {
      background-color: rgba(255, 255, 255, 0.85);
    }
    .txt-en {
      color: #fff;
      @include ffRu;
      @include font(26,31,100,900);
      font-style: italic;
    }
    .txt-ja {
      color: #fff;
      @include font(15,22,150,bold);
      margin-top: 10px;
    }
  }
  .img01 {
    max-width: 820px;
    margin: 0 auto;
  }
  @include SP {
    padding: 39px 0 0;
    .cm-ttl01 {
      margin-bottom: 40px;
    }
    .box-coming {
      height: 112px;
      padding: 26px 10px 15px;
      .txt-en {
        @include font(20,24);
      }
      .txt-ja {
        @include font(14,20);
      }
    }
  }
}

.sec04 {
  padding: 75px 0 113px;
  .cm-ttl01 {
    margin-bottom: 57px;
    .ttl-en {
      @include font(42,58,150);
      em {
        @include ffNS;
        @include font(38,56,150,bold);
        font-style: normal;
      }
    }
  }
  .list-supervisor {
    @include flexbox;
    @include flex-wrap(wrap);
    .item {
      text-align: center;
      padding: 0 10px;
      &-thumb {
        max-width: 165px;
        margin: 0 auto 16px;
        @include border-radius(50%);
      }
      a {
        display: block;
        position: relative;
        .js-image {
          @include aspect-ratio(165, 165);
          @include border-radius(50%);
        }
      }
      .name {
        @include ffRu;
        @include font(20,24,100,600);
        margin-bottom: 9px;
      }
      .pos {
        @include ffRu;
        @include font(13,15,100,500);
        margin-bottom: 13px;
      }
      .desc {
        @include font(14,22,0);
        text-align: left;
      }
    }
    @include PC {
      .item {
        width: 25%;
        margin: 0 0 49px;
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
  .sec04-btn01 {
    text-align: center;
    margin-top: 12px;
    .cm-btn02 {
      margin: 0 auto;
    }
  }
  @include SP {
    padding: 59px 0 73px;
    .cm-ttl01 {
      margin-bottom: 35px;
      .ttl-en {
        @include font(30,38);
        em {
          @include font(26,38);
        }
      }
    }
    .list-supervisor {
      .item {
        padding: 0 12px;
        width: 50%;
        margin: 0 0 11px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &-thumb {
          max-width: 115px;
          margin: 0 auto 20px;
        }
        a {
          .js-image {
            @include aspect-ratio(165, 165);
          }
        }
        .name {
          @include font(14,17);
          margin-bottom: 7px;
        }
        .pos {
          @include font(11,13);
          margin-bottom: 11px;
        }
        .desc {
          @include font(12,22);
        }
      }
    }
    .sec04-btn01 {
      margin-top: 28px;
      .cm-btn01 .c-arr {
        right: 14px;
      }
    }
  }
}
